<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div
        class="content-wrapper container-xxl p-0 font"
        v-if="permissionCheck.viewAny == false"
      >
        <div class="p-2">
          <img style="width: 100%" src="/assets/image/accessDenide.png" alt="" />
        </div>
      </div>
      <div class="content-wrapper container-xxl p-0 font" v-else>
        <banner
          title="clients"
          :breadcrumb="[
            {
              label: 'Staff',
            },
            { label: 'clients' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="content-body" style="margin-top: -50px">
          <!-- users list start -->
          <section>
            <div class="row" style="margin-top: -20px">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="card custom-card">
                  <div class="card-body">
                    <div class="row">
                  <div class="col text font">

                    <h4>
                      <b style="color: #00364f; font-weight: bolder">Clients </b>
                    </h4>
                  </div>
                  <div class="col text-end ">
                    <button type="button" @click="loadFormNull()" data-bs-toggle="modal"
                      data-bs-target="#masterDistributerModal" title="Add New" style="background-color: #f21000"
                      class="btn text-white btn-sm">+ New</button>
                    &nbsp;<button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>
                    <div class="row">
                    <div
                      class="col-md-12 col-xl-12 col-sm-12"
                    
                    >
                      <div class="container-fluid table-scroll">
                        <table class="table table-hover table-sm">
                          <tr
                            class="text tr-head table-wrapper-scroll-y rounded-circle"
                            style="background-color: white"
                          >
                            <th
                              class="text-truncate"
                              style="
                                background-color: #cdcdcd;
                                border-radius: 8px 0px 0px 8px;
                              "
                            >
                              Code
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Name
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Phone
                            </th>

                            <th style="background-color: #cdcdcd" class="text-truncate">
                              Email
                            </th>
                            <!-- <th style="background-color: #cdcdcd" class="text-truncate">
                              PanNo.
                            </th> -->
                            <th style="background-color: #cdcdcd" class="text-truncate">
                              Admin
                            </th>
                            <th style="background-color: #cdcdcd" class="text-truncate">
                              Retailer
                            </th>
                        
                            
                            <!-- <th style="background-color: #cdcdcd" class="text-truncate">
                              Invoice
                            </th>

                            <th style="background-color: #cdcdcd" class="text-truncate">
                              Due Balance
                            </th> -->

                            <th
                              style="background-color: #cdcdcd"
                              class="text-truncate text-center"
                            >
                              Kyc
                            </th>
                            <th
                              style="background-color: #cdcdcd"
                              class="text-truncate text-center"
                            >
                              Status
                            </th>
                            <th
                              v-if="permissionCheck.view != false"
                              style="
                                background-color: #cdcdcd;
                                border-radius: 0px 8px 8px 0px;
                              "
                              class="text-truncate text-end"
                            >
                              Action
                            </th>
                          </tr>

                          <tbody class="text">
                            <tr v-for="(client, index) in clients" :key="index">
                              <td v-if="client">
                                {{ client.code }}
                              </td>
                              <td class="text-truncate" v-if="client">{{ client.fname }} {{ client.lname }}</td>
                              <td v-if="client">{{ client.phone }}</td>
                              <td v-if="client">{{ client.email }}</td>

                              <!-- <td v-if="client">{{ client.pan }}</td> -->
                              <td v-if="client">
                          


                              <span v-if="client.admin" class="text-truncate">
                                <strong>{{ client.admin.orgnization }}</strong></span
                              >
                              <br />
                              <span v-if="client.admin" class="text-truncate"
                                >
                                {{ client.admin.code }}
                                <small
                                  >(<i>{{ client.admin.name }}</i
                                  >)</small
                                >
                              </span>
                              </td>
                              <td v-if="client">
                          


                          <span v-if="client.retailer" class="text-truncate">
                            <strong>{{ client.retailer.name }}</strong></span
                          >
                          <br />
                          <span v-if="client.retailer" class="text-truncate"
                            >
                            {{ client.retailer.code }}
                            <!-- <small
                              >(<i>{{ client.retailer.name }}</i
                              >)</small
                            > -->
                          </span>
                          </td>
                              <!-- <td class="text-truncate">
                              <span v-if="client.clientServiceTotal">
                                {{
                                  parseInt(client.clientServiceTotal) +
                                  parseInt(client.clientServiceGst)
                                }}</span
                              >
                              <span v-else>0</span>
                            </td>
                             
                            <td class="text-truncate">
                              <span v-if="client.clientPaymentTotal">
                                {{parseInt(client.clientServiceTotal) +
                                  parseInt(client.clientServiceGst) - parseInt(client.clientPaymentTotal) }}</span
                              >
                              <span v-else>

                                <span v-if="client.clientServiceTotal">
                                  {{parseInt(client.clientServiceTotal) +
                                  parseInt(client.clientServiceGst) }}</span>
                                  <span v-else>
      0
</span>
                                </span>
              
                                
                                
                                
                            
                            </td> -->


                            <td style="padding: 0px 15px 0px 15px" class="text-truncate">
                              <span v-if="client.client_kyc">
                                <p
                                  v-if="
                                    client.client_kyc.kycStatus ==
                                    'approve'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>
                                    {{
                                      client.client_kyc.kycStatus
                                    }}</b
                                  >
                                </p>

                                <p
                                  v-if="
                                    client.client_kyc.kycStatus ==
                                    'cancel'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #f21000;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>
                                    {{
                                      client.client_kyc.kycStatus
                                    }}</b
                                  >
                                </p>

                                <p
                                  v-if="
                                    client.client_kyc.kycStatus ==
                                    'reject'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: black;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>
                                    {{
                                      client.client_kyc.kycStatus
                                    }}</b
                                  >
                                </p>

                                <p
                                  v-if="
                                    client.client_kyc.kycStatus ==
                                    'false'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: rgb(235, 133, 16);
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                <b>Pending</b
                                  >
                                </p>
                              </span>
                              <span v-else> 
                                <p
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: rgb(235, 133, 16);
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>Pending</b
                                  >
                                </p>
                              </span>
                            </td>


                              <td v-if="client">
                                <p
                                  v-if="client.loginStatus == 'true'"
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    box-shadow: 2px 2px 5px gray;
                                    width: 70px;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  Active
                                </p>
                                <p
                                  v-else
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #f21000;
                                    box-shadow: 2px 2px 5px gray;
                                    width: 70px;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  InActive
                                </p>
                              </td>
                              <td class="text-end" v-if="permissionCheck.view != false">
                                <div class="btn-group btn-group-sm" role="group">

                                  <router-link
                                  title="View"
                                  v-if="client"
                                  :to="`clients-profile/${client.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>


                                <button
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kycModal"
                                  @click="clientkycValueAsign(client)"
                                  class="btn btns btn-sm text-dark"
                                  style="
                                    width: 27px;
                                    background-color: yellow;
                                    padding: 4.5px 4.5px 4.5px 4.5px;
                                  "
                                >
                                  <font-awesome-icon icon="file" />
                                </button>
                                <button
                                title="Verify"
                                  v-if="client.client_kyc"
                                  @click="clientkycValueApprove(client)"
                                  class="btn btns btn-sm"
                                  style="
                                    width: 27px;
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #1426f5;
                                    color: white;
                                  "
                                  data-bs-toggle="modal"
                                  data-bs-target="#approoveKycModal"
                                >
                                  <b>
                                    <font-awesome-icon icon="tag" />
                                  </b>
                                </button>
                                  </div>
                         

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  </div>
             
                  <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadClients(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadClients(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadClients(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadClients(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadClients(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadClients(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadClients(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadClients(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadClients(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadClients(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadClients(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadClients(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- model  -->
  <!-- Button trigger modal -->



  <!-- Modal -->
  <div
    class="modal fade"
    id="kycModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text" id="exampleModalLabel">
            <b>Client Kyc</b>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row" v-if="msgImgError">
            <div class="col">
              <div class="alert alert-danger p-1" role="alert">
                {{ msgImgError }}
              </div>
            </div>
          </div>
          <form
            @submit.prevent="
              clientKycVal == 'false'
                ? clientKycCreate()
                : clientUpdateKyc()
            "
          >
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                <th>+</th>
                <th>Documents</th>
                <th>Action</th>
              </tr>
              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Client Aadhaar Front</b>
                    <input
                      @change="uploadAdharFront"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="AdharFront"
                      type="file"
                      accept="image/*"
                    />
                    <input
                      type="number"
                      v-model="form.aadharNo"
                      class="form-control"
                      placeholder="Adhar Number"
                      style="height: 30px"
                      
                      required
                    />
                    <!-- @change="lenthheker($event, 'clientKycAddar')" -->
                    <span style="color: #f21000" id="clientKycAddar"></span>
                    <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.aadharNo">{{
                          form.errors.error.aadharNo[0]
                        }}</span></span
                      ></small
                    >
                  </td>
                  <td class="text-truncate text-end">
                    <label for="AdharFront">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Client Aadhaar Back</b>
                    <input
                      @change="uploadAdharBack"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="AdharBack"
                      type="file"
                      accept="image/*"
                    />
                  </td>
                  <td class="text-truncate text-end">
                    <label for="AdharBack">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Client Pan</b>
                    <input
                      @change="uploadPanPhoto"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="panphoto"
                      type="file"
                      accept="image/*"
                    />
                    <!-- <input
                      type="tel"
                      v-model="form.panNo"
                      class="form-control"
                      placeholder="Pan Number"
                      style="height: 30px"
                      maxlength="10"
                      required
                    /> -->

                    <input
                      v-if="panVarifyingStatus == 'true'"
                      disabled
                      type="tel"
                      maxlength="10"
                      v-model="form.panNo"
                      class="form-control"
                      placeholder="Pan Number"
                      style="height: 30px; text-transform: uppercase"
                      required
                      @change="businessPanVeryfied()"
                      @keydown="nameKeydown($event)"
                    />
                    <input
                      v-else
                      type="tel"
                      maxlength="10"
                      v-model="form.panNo"
                      class="form-control"
                      placeholder="Pan Number"
                      style="height: 30px; text-transform: uppercase"
                      required
                      @change="businessPanVeryfied()"
                      @keydown="nameKeydown($event)"
                    />
                    <span style="color: #f21000" id="adminPanVeryfied"></span>
                    <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.panNo">{{
                          form.errors.error.panNo[0]
                        }}</span></span
                      ></small
                    >
                  </td>
                  <td class="text-truncate text-end">
                    <label for="panphoto">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <!-- Modal approoveKycModal-->

  <div
    class="modal fade"
    id="approoveKycModal"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel">
            <strong class="text">Approve Kyc</strong>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="table-wrapper-scroll-y text tr-head rounded-circle">
                <th>+</th>
                <th>Ducument Name</th>
                <th class="text-end">Download</th>
              </tr>

              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 50px; height: 50px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Client Aadhaar Front&nbsp;&nbsp;</b>
                    <span>{{ this.aadharNoForKyc }}</span>
                    <select
                      @change="aadharKycApprovedSave()"
                      class="form-select"
                      v-model="aadharapproveKyc.status"
                    >
                      <option value="approve">Approve</option>
                      <!-- <option value="reject">Reject</option> -->
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <!-- <label for="AdharFront"> -->
                    <button
                      @click="
                        extensionkycAdharFront.toLowerCase() == 'pdf'
                          ? openWindow(adharFrontImgPdf)
                          : openWindow(adharFrontImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 50px; height: 50px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Client Aadhaar Back</b>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycAdharBack.toLowerCase() == 'pdf'
                          ? openWindow(adharBackImgPdf)
                          : openWindow(adharBackImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 50px; height: 50px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Client Pan &nbsp;&nbsp;</b>
                    <span> {{ this.panNoForKyc }}</span>
                    <select
                      v-model="panapproveKyc.status"
                      @change="panKycApprovedSave()"
                      class="form-select"
                    >
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycPanPhoto.toLowerCase() == 'pdf'
                          ? openWindow(panImgPdf)
                          : openWindow(panImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <!-- <img style="width: 50px; height: 50px" v-if="panImg" :src="panImg" /> -->
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Client Kyc</b>
                    <select v-model="approveKyc.status" class="form-select">
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                    <br />
                    <textarea
                      v-model="approveKyc.remark"
                      rows="2"
                      class="form-control"
                      placeholder="Remark"
                    ></textarea>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      type="button"
                      @click="clientKycApprovedSave"
                      class="btn btns text-white btn-sm"
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    >
                      <font-awesome-icon icon="floppy-disk" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <!-- <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button> -->
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>



<!-- Right Sidebar starts -->
<div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



       

            <ul class="list-group list-group-flush">
  <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="code" class="form-check-input" type="radio" name="flexRadioDefault" id="code">
  <label class="form-check-label" for="code">
    Code
  </label>
</div>
  </li>
  <!-- <li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="orgnization" class="form-check-input" type="radio" name="flexRadioDefault" id="orgnization">
  <label class="form-check-label" for="orgnization">
    
    Orgnization
  </label>
</div>
</li> -->
  <!--   <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="dueDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Due Date
  </label>
</div>
</li> -->
<!-- <li class="list-group-item">
    <div class="form-check">
  <input v-model="filterType" value="completionDate" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
  <label class="form-check-label" for="flexRadioDefault1">
  Completion Date
  </label>
</div>
  </li> -->
  <li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="name" class="form-check-input" type="radio" name="flexRadioDefault" id="name">
  <label class="form-check-label" for="name">
    Name
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="email" class="form-check-input"  type="radio" name="flexRadioDefault" id="email">
  <label class="form-check-label" for="email">
    email
  </label>
</div></li>

<li class="list-group-item"><div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="phone" class="form-check-input"  type="radio" name="flexRadioDefault" id="phone">
  <label class="form-check-label" for="phone">
    phone
  </label>
</div></li>
<li class="list-group-item">
    <div class="form-check">
  <input  @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio" name="flexRadioDefault" id="all">
  <label class="form-check-label" for="all">
    All
  </label>
</div>
  </li>
</ul>


<div class="pt-1 pb-1">
  <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
 <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 <input :type="filterType == 'phone' ? 'number' : 'text'" v-if="filterType != 'all'" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">
 <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
 







</div>

<button @click="loadClients()" type="button" class="btn btn-success btn-sm">Success</button>



           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->



</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Permissions from "../../../mixins/permission";
import Banner from "../../../components/staff/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
import Form from "vform";

// var _ = require('lodash');
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "StaffClients",
  mixins: [Permissions],
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {

      filterValue:'',
      filterType:'',
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,

      msgImgError: "",

      loading: false,
      clients: [],

      // admin kyc form vars
      placeholderImg: this.$store.state.placeholderImg,

      adminKycVal: "false",

      adminInformation: {},
      // permissions

      panVarifyingStatus: "false",
      submitStatus: "false",

      clientName: "false",
      phonePro: "false",
      altPhonePro: "false",
      doBDate: "false",
      ClientCity: "false",
      ClientAddress: "false",
      bussinessCity: "false",
      businessAddress: "false",
      pinCode: "false",
      businessPinCode: "false",
      businessName: "false",
      gstinVal: "false",

      proPassword: "false",
      // doiDate: "false",

      aadharNoForKyc: "",
      panNoForKyc: "",
      photo: this.$store.state.placeholderImg,
      kycModalHeading: "",
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImgPdf: "",
      adharFrontImgPdf: "",
      adharBackImgPdf: "",
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      states: [],
    
      photoPreImg: this.$store.state.placeholderImg,
      bussinessPanPreImg: this.$store.state.placeholderImg,
      logoPreImg: this.$store.state.placeholderImg,
      extension: "",
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      extensionkycPassbookPhoto: "",
      extensionProfile: "",
      form: new Form({
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
        errors:{}
      }),
      edit: "false",
      clientType: [],
      clientServices: [],
      profServices: [],
      client_id: null,
      clientkycs: [],
      clientKycVal: "false",
      approveKyc: new Form({
        client_id: null,
        type: "kyc",
        status: "cancel",
        remark: "",
      }),
      aadharapproveKyc: new Form({
        client_id: null,
        type: "aadhar",
        status: "cancel",
        remark: "Aadhar Kyc",
      }),
      panapproveKyc: new Form({
        client_id: null,
        type: "pan",
        status: "cancel",
        remark: "",
      }),
      accountapproveKyc: new Form({
        client_id: null,
        type: "account",
        status: "cancel",
        remark: "",
      }),
      clientKycAddar: "false",
    
      permissionCheck: {
        view: "",
        viewAny: "",
        kycView: "",
        kycCreate: "",
        kycUpdate: "",
        kycApprove: "",
      },
    };
  },
  methods: {
    valueNullFilter(){
  this.filterValue = "";
},
clientkycValueAsign(value) {
  this.form = {};
            this.form.errors = {};
      this.panImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.adharFrontImg = this.$store.state.placeholderImg;

      this.clientKycAddar = "false";
          $("#clientKycAddar").html("<small></small>");


          this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");
      this.client_id = value.id;
      this.form.client_type_id = value.client_type_id;
      this.form.panNo = null;
      this.form.panPhoto = "";
      this.form.accountType = null;
      this.form.aadharNo = "";
      this.form.remark = "";
      this.form.aadharBackPhoto = "";
      this.form.aadharFrontPhoto = "";
      this.form.passbookPhoto = "";
      this.form.accountNo = null;
      if (value.client_kyc == null) {
        this.panVarifyingStatus = "false";
        this.kycModalHeading = "client Kyc";
        this.clientKycVal = "false";
      } else {
        this.panVarifyingStatus = "true";
        this.kycModalHeading = "client Kyc";
        this.form = value.client_kyc;
        if (value.client_kyc.panPhoto) {
          if (value.client_kyc.panPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/client/" +
              value.id +
              "/" +
              value.client_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/client/" +
                value.id +
                "/" +
                value.client_kyc.panPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/client/" +
              value.id +
              "/thumbs/" +
              value.client_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/client/" +
                value.id +
                "/thumbs/" +
                value.client_kyc.panPhoto;
            }

            this.extensionkycPanPhoto = this.panImg.split(".").pop();

            if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
              this.panImg = "/assets/image/pdf.png";
            }
          } else {
            this.panImg = this.$store.state.placeholderImg;
          }
        }
        if (value.client_kyc.passbookPhoto) {
          if (value.client_kyc.passbookPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/client/" +
              value.id +
              "/" +
              value.client_kyc.passbookPhoto
            ) {
              this.passBookImg =
                this.$store.state.imgUrl +
                "/client/" +
                value.id +
                "/" +
                value.client_kyc.passbookPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/client/" +
              value.id +
              "/thumbs/" +
              value.client_kyc.passbookPhoto
            ) {
              this.passBookImg =
                this.$store.state.imgUrl +
                "/client/" +
                value.id +
                "/thumbs/" +
                value.client_kyc.passbookPhoto;
            }

            this.extensionkycPassbookPhoto = this.passBookImg.split(".").pop();

            if (this.extensionkycPassbookPhoto == "pdf" || this.extensionkycPassbookPhoto == "PDF") {
              this.passBookImg = "/assets/image/pdf.png";
            }
          } else {
            this.passBookImg = this.$store.state.placeholderImg;
          }
        }
        if (value.client_kyc.aadharFrontPhoto) {
          if (value.client_kyc.aadharFrontPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/client/" +
              value.id +
              "/" +
              value.client_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/client/" +
                value.id +
                "/" +
                value.client_kyc.aadharFrontPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/client/" +
              value.id +
              "/thumbs/" +
              value.client_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/client/" +
                value.id +
                "/thumbs/" +
                value.client_kyc.aadharFrontPhoto;
            }

            this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

            if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
              this.adharFrontImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }
        }
        if (this.form.aadharBackPhoto) {
          if (this.form.aadharBackPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/client/" +
              value.id +
              "/" +
              value.client_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/client/" +
                value.id +
                "/" +
                value.client_kyc.aadharBackPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/client/" +
              value.id +
              "/thumbs/" +
              value.client_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/client/" +
                value.id +
                "/thumbs/" +
                value.client_kyc.aadharBackPhoto;
            }

            this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

            if (this.extensionkycAdharBack == "pdf" ||  this.extensionkycAdharBack == "PDF") {
              this.adharBackImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }
        }

        this.clientKycVal = "true";
      }
    },
    clientKycCreate() {
      if (
        this.clientKycAddar == "false" &&
        this.msgImgError == "" &&
        this.submitStatus == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("client_id", this.client_id);
        this.loading = true;
        this.$axios
          .post("staff/clientkyc", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Kyc Created Succesfully", {
              autoClose: 1000,
            });
         
            this.form = {};
            this.form.errors = {};
            $("#kycModal").modal("hide");
            this.loadClients();
            //    this.$router.push({ name: 'Admins' })
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.form.errors = error.response.data;
       
              this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
           
          
          })

      }
    },
    uploadAdharFront(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Front File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharFront").value;
        this.extensionkycAdharFront = fileName.split(".").pop();

        if (
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "jpeg" ||
          this.extensionkycAdharFront == "png" || 

          this.extensionkycAdharFront == "PDF" ||
          this.extensionkycAdharFront == "JPG" ||
          this.extensionkycAdharFront == "JPEG" ||
          this.extensionkycAdharFront == "PNG"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharFrontPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG ,JPEG , PNG , PDF ";
        }

       
        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      }


    },
    uploadAdharBack(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Aadhar Back  File Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#AdharBack").value;
        this.extensionkycAdharBack = fileName.split(".").pop();

        if (
          this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "jpeg" ||
          this.extensionkycAdharBack == "png" || 

          this.extensionkycAdharBack == "PDF" ||
          this.extensionkycAdharBack == "JPG" ||
          this.extensionkycAdharBack == "JPEG" ||
          this.extensionkycAdharBack == "PNG"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharBackPhoto = e.target.files["0"];
        } else {
          this.msgImgError = "File type must JPG , PNG , PDF, JPEG ";
        }

       
        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      }
    },
    uploadPanPhoto(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        this.panImg = URL.createObjectURL(e.target.files["0"]);
        this.form.panPhoto = e.target.files["0"];
        var fileName;
        fileName = document.querySelector("#panphoto").value;
        this.extensionkycPanPhoto = fileName.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "clientKycAddar") {
        if (data.length != 12) {
          this.clientKycAddar = "true";

          $("#clientKycAddar").html(
            "<small>Aadhar required minimum 12 letters</small>"
          );
        }
        if (data.length == 12) {
          this.clientKycAddar = "false";
          $("#clientKycAddar").html("<small></small>");
        }
      }
      

   
    },
    clientUpdateKyc() {
      if (
        this.clientKycAddar == "false" &&
        this.msgImgError == "" &&
        this.submitStatus == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("client_id", this.client_id);
        this.loading = true;
        this.$axios
          .post(`staff/clientkyc/${this.form.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;

            toast.success("Kyc Update Succesfully", {
              autoClose: 1000,
            });
       
            this.form = {};
            this.form.errors = {};
            $("#kycModal").modal("hide");
            this.loadClients();
        
            //    this.$router.push({ name: 'Admins' })
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.form.errors = error.response.data;
     
            var msg = ""

            if(error.response.data.error){
              msg =  error.response.data.error;

              if(error.response.data.error.panNo){
                  msg = ""
              }

              if(error.response.data.error.aadharNo){
                msg = ""
}

            }


            if(msg){
              this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" +msg 
                
                
                +"</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
            }
     
   
              
           
          
          })
      }
    },
    openWindow(url) {
      window.open(`${url}`);
    },
    clientkycValueApprove(value) {
      this.panImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.adharFrontImg = this.$store.state.placeholderImg;

      this.approveKyc.status = value.client_kyc.kycStatus;
      this.approveKyc.remark = value.client_kyc.remark;
      this.panapproveKyc.status = value.client_kyc.panStatus;
      this.aadharapproveKyc.status = value.client_kyc.aadharStatus;

      this.approveKyc.client_id = value.id;
      this.accountapproveKyc.client_id = value.id;
      this.panapproveKyc.client_id = value.id;
      this.aadharapproveKyc.client_id = value.id;

      // this.form = value.admin_kyc

      if (value.client_kyc.panPhoto != null) {
        // this.panImg = this.$store.state.imgUrl + '/client/' + value.id + '/thumbs/' + value.client_kyc.panPhoto

        if (
          this.$store.state.imgUrl +
          "/client/" +
          value.id +
          "/thumbs/" +
          value.client_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/client/" +
            value.id +
            "/thumbs/" +
            value.client_kyc.panPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/client/" +
          value.id +
          "/" +
          value.client_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/client/" +
            value.id +
            "/" +
            value.client_kyc.panPhoto;
        }
        this.panImgPdf = this.panImg;
        this.extensionkycPanPhoto = this.panImg.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

      if (value.client_kyc.aadharFrontPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/client/" +
          value.id +
          "/thumbs/" +
          value.client_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/client/" +
            value.id +
            "/thumbs/" +
            value.client_kyc.aadharFrontPhoto;
        }

        if (
          this.$store.state.imgUrl +
          "/client/" +
          value.id +
          "/thumbs/" +
          value.client_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/client/" +
            value.id +
            "/" +
            value.client_kyc.aadharFrontPhoto;
        }

        this.adharFrontImgPdf = this.adharFrontImg;
        this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharFrontImg = this.$store.state.placeholderImg;
      }

      if (value.client_kyc.aadharBackPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/client/" +
          value.id +
          "/thumbs/" +
          value.client_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/client/" +
            value.id +
            "/thumbs/" +
            value.client_kyc.aadharBackPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/client/" +
          value.id +
          "/" +
          value.client_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/client/" +
            value.id +
            "/" +
            value.client_kyc.aadharBackPhoto;
        }

        this.adharBackImgPdf = this.adharBackImg;
        this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharBackImg = this.$store.state.placeholderImg;
      }
    },
    clientKycApprovedSave() {
      var kyc;
      if (this.approveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.approveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.approveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("staff/approveclientkyc", this.approveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("client Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
          this.form = {};
          //    this.$router.push({ name: 'Admins' })
          this.loadClients();
          $("#approoveKycModal").modal("hide");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
            // this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
    },
    aadharKycApprovedSave() {
      var kyc;
      if (this.aadharapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.aadharapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.aadharapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("staff/approveclientkyc", this.aadharapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Aadhar Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
      
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
            // this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
    },

    panKycApprovedSave() {
      var kyc;
      if (this.panapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.panapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.panapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("staff/approveclientkyc", this.panapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          toast
            .success("Pan Kyc " + kyc + " Succesfully", {
              autoClose: 1000,
            })
            .finally(() => (this.loading = false))
            .catch((error) => {
            // this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });

          //    this.$router.push({ name: 'Admins' })
        }) .finally(() => (this.loading = false))
        .catch((error) => {
            // this.submitStatus = "true";
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
    },

    permissionsCheker() {
      this.permissionCheck.view = this.loadPermissions("client-view");
      this.permissionCheck.viewAny = this.loadPermissions("client-view-any");
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadClients();
    },
    adminlogofinder(client) {
      var img;

      if (client.photo != null) {
        img =
          this.$store.state.imgUrl + "/client/" + client.id + "/thumbs/" + client.photo;
        return img;
      } else {
        img = this.$store.state.placeholderImg;
        return img;
      }
    },
    loadClients(page = null, pg = null) {
  
     
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
     


     if( this.filterType == 'all'){
         this.filterValue = ''
         this.filterType = ''
       }


       if(this.filterValue != null && this.filterType != null){
               if(this.filterType == 'name'){
                 pageUrl += `staff/client?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
      
               }else{
                 pageUrl += `staff/client?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
      
               }
       }else{
         pageUrl += `staff/client?per_page=${this.per_page}`;
        }

   } else {
     pageUrl += `&per_page=${this.pagination.per_page}`;
   }
   if (this.table_search) {
     pageUrl += `&searchTerm=${this.table_search}`;
   }
   if (page != null) {
     pageUrl += `&page=${page}`;
   }
   this.loading = true;
      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.clients = res.data.data.data;
          this.pagination = res.data.data;
          this.filterValue = ""
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.staffPageTitles.client
    console.log('--------------------------');
    }

  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClients();
      this.permissionsCheker();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -20px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}


.form-check-input:checked {
    background-color: #ffffff;
    border-color: #f21000;
    border-width: 5px;
}
.form-check-input:not(:disabled):checked {
    box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}




.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
